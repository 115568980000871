<template>
    <div>
      <v-row wrap justify="center" style="background-color: #f0f0f0" class="pt-lg-8">
        <v-col cols="12" class="text-center py-4 pt-4">
          <span class="itemHeading">
            Privacy Policy - Attendance ManagementIntroduction

          </span>
        </v-col>
        <v-col cols="10" sm="11" md="10" lg="11" xl="11" class="text-justify" style="font-family:kumbhMedium">
          <span style=" font-size: 16px; color: #1a1919;font-family:kumbhMedium">
           
           
            This Privacy Policy explains how Attendance Management ("we", "our", or "us") collects, uses, and shares information when you use our Attendance Management application.
            <br /><br />
            <span style="font-weight: bold;">Information We Collect:</span><br>
            <span style="font-weight: bold;">1.  Personal Information:</span>
            <br />
            <ul>
              <li>
                Employee name, contact details, and employment information
              </li>
              
              <li>
                Account credentials
              </li>
              <li>
                Profile images (if provided)</li>
            </ul>
            <br />
            <span style="font-weight: bold;">2. Location Data:</span>
            <br />
            <ul>
              <li>
                Real-time location data when employees check in/out              </li>
              <li>
                Location history for attendance verification purposes              </li>
            </ul>
            <br />
            <span style="font-weight: bold;">3. Usage Data:</span>
            <br />
            <ul>
              <li>
                Attendance records (check-in/out times)              </li>
              <li>
                Leave requests and approvals              </li>
              <li>
                Overtime records              </li>
                <li>
                    Device information (device model, operating system, IP address)              </li>
            </ul>
            <br />
            <span style="font-weight: bold;">4. How We Use Your Information:</span>
            <br />
            <ul>
              <li>
                To provide attendance tracking and management services              </li>
              <li>
                To process and manage leave requests              </li>
                <li>
                    To generate attendance reports             </li>
                    <li>
                        To send real-time notifications regarding attendance and leave status          </li>
                        <li>
                            To improve and optimize our application         </li>
                            

            </ul>
<br>
            <span style="font-weight: bold;">5. Data Storage and Security:</span>
            <br />
            <ul>
              <li>
                All data is stored securely in encrypted form            </li>
              <li>
                We implement appropriate technical and organizational measures to protect your data        </li>
                <li>
                    Data is backed up regularly to prevent loss           </li>
                    
            </ul>
            <br />
            <span style="font-weight: bold;">Data Sharing:</span><br>
            <span style="font-weight: bold;">6. We do not sell your personal information. We may share data with:</span>
            <br />
            <ul>
              <li>
                Your employer/organization administrators             </li>
              <li>
                Third-party service providers that help us operate our services (cloud storage, analytics)            </li>
                <li>
                    Legal authorities when required by law            </li>
                    
            </ul>
            <br />
            
            <span style="font-weight: bold;">Employee Rights:</span><br>
            <span style="font-weight: bold;">7. Employees have the right to:</span>
            <br />
            <ul>
              <li>
                Access their personal data              </li>
                <li>
                    Request corrections to inaccurate data              </li>
                    <li>
                        Request deletion of their data (subject to legal retention requirements)             </li>
                       
            </ul>
            <br />
            
            <span style="font-weight: bold;">Data Retention:</span><br>


            We retain your data for as long as necessary to fulfill the purposes outlined in this policy, or as required by law and legitimate business interests.
            <br /><br />

            <span style="font-weight: bold;">Changes to This Policy:</span><br>


            We may update this Privacy Policy periodically. We will notify users of any material changes via the application.
            <br /><br />

            <span style="font-weight: bold;">Contact Us:</span><br>


            If you have questions about this Privacy Policy or our data practices, please contact us at: info@leopardtechlabs.com
            <br /><br />
            
          </span>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PrivacyPolicy'
  };
  </script>
  
  <style scoped>
  .text-justify {
    text-align: justify;
  }
  
  .text-center {
    text-align: center;
  }
  
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .pt-8 {
    padding-top: 2rem;
  }
  

  </style>
  